import React, { FunctionComponent, ReactElement } from 'react';
import { useIsMobile } from '../../../utils/hooks/windowSize';
import styles from './NavBarItem.module.css';

export interface NavBarItemProps {
  value: string;
  label: string;
  active: boolean;
  onClick: () => void;
  ignoreMobile?: boolean;
}

const NavBarItem: FunctionComponent<NavBarItemProps> = (props): ReactElement => {
  const isMobile = useIsMobile();

  if (isMobile && !props.ignoreMobile) {
    return (
      <div className={`${styles.mobileWrapper} ${props.active ? styles.mobileActive : ''}`}>
        <button className={styles.mobileButton} onClick={props.onClick}>
          {props.label}
        </button>
      </div>
    );
  }

  return (
    <div className={`${styles.wrapper} ${props.active ? styles.active : ''}`}>
      <button className={styles.button} onClick={props.onClick}>
        {props.label}
      </button>
    </div>
  );
};

export default NavBarItem;
