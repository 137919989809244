import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isProd } from '../utils/environment';

/**
 * @NOTE: when adding new routes, the route path is important to note:
 * 1. Anything that doesn't have subpaths is considered a root path and
 *    will not have a back button.
 * 2. Anything that has subpaths will have a back button that uses the path fragments
 *    as a breadcrumb for the back button.
 */
export enum PageRoute {
  HomePage = '/',
  PatientsPage = '/patients',
  CaregiversPage = '/caregivers',
  NursesPage = '/nurses',
  ContactPage = '/contact',
  LoginPage = '/app/login',
  PocketRNLoginPage = 'https://www.pocketrn.com/app/login',
  PocketRNRedirectPage = '/pocketrn/login',
  AppPage = '/app',
}

export default class PageRoutes {
  public static popPath(path: string): string {
    const pathSplit = path.split('/');
    pathSplit.pop();
    return pathSplit.join('/');
  }

  public static getRootPathFragment(path: string): string {
    const pathSplit = path.split('/');
    if (pathSplit.length > 1) {
      return pathSplit[1];
    }
    return '';
  }

  public static redirectOnAppPath(path: string): boolean {
    const url = new URL(window.location.href);
    if (PageRoutes.getRootPathFragment(path) === 'app') {
      // @NOTE: this would mean the redirect didn't work
      if (url.searchParams.get('appRedirect')) {
        return false;
      }
      url.searchParams.set('appRedirect', window.location.pathname);
      url.pathname = '/app';
      window.location.replace(url.toString());
      return true;
    }
    if (url.pathname === '/__/auth/action') {
      const subDomain = isProd() ? 'core-functions-prod' : 'core-functions-dev';
      const newUrl = new URL(`https://${subDomain}.firebaseapp.com/__/auth/action`);
      url.searchParams.forEach((value, key) => newUrl.searchParams.set(key, value));
      window.location.replace(newUrl.toString());
      return true;
    }
    return false;
  }
}

export function useForegroundPage(): boolean {
  const [ hasForeground, setHasForeground ] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const isRootPath = currentPath.split('/').length === 2;
    setHasForeground(!isRootPath);
  }, [ location ]);
  return hasForeground;
}
