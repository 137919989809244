import React, { FunctionComponent, ReactElement } from 'react';
import styles from './Hamburger.module.css';

interface HamburgerIconProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

// @SOURCE: https://codepen.io/RRoberts/pen/ZBYaJr
const HamburgerIcon: FunctionComponent<HamburgerIconProps> = (props): ReactElement => {
  const toggle = () => {
    props.toggleMenu();
  };

  return (
    <div className={styles.wrapper} onClick={toggle}>
      <div className={props.isOpen ? styles.open : ''}>
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
      </div>
    </div>
  );
};

export default HamburgerIcon;
