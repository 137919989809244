import React from 'react';

export const LOCALE_NAME_ENGLISH = 'EN';

/* eslint-disable max-len, react/display-name, react/no-unescaped-entities, @typescript-eslint/explicit-module-boundary-types, react/jsx-no-literals, react/jsx-tag-spacing, react/self-closing-comp */
export const localeEn = {
  ds: {
    button: {
      countdown: {
        seconds: seconds => `${seconds} s`,
      },
    },
  },
  main: {
    signUp: 'Sign up',
    learnMore: 'Learn More',
    patient: 'I\'m a Patient',
    caregiver: 'I\'m a Caregiver',
    nurse: 'I\'m a Nurse',
    copyright: year => `© ${year} Pocket RN`,
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms',
  },
  pocketRNRedirectPage: {
    banner: {
      title: <span>Under the covers, <b>Pocket HC</b> uses the <b>Pocket RN</b> platform for handling all your home health care needs.</span>,
      description: 'By logging into Pocket RN you will have access to all your home care experts, not just nurses.',
    },
    redirectToPocketRN: 'Go to Pocket RN',
  },
  mainPage: {
    banner: {
      title: isHc => isHc ? 'Home care health experts anytime, anywhere.' : 'Nursing is caring.',
      description: isHc => isHc ? 'Pocket HC enables patients & caregivers to video-chat home care guides in-between visits.' : 'Pocket RN enables patients & caregivers to video-chat nurses with the right expertise, at the right time.',
      alt: 'Three nurses making hearts with their hands',
    },
  },
  patientsPage: {
    banner: {
      title: '24/7 access to nurses.',
      description: 'On-demand nursing assistance with your medication, wound care, stoma care, and so much more over video-chat.',
    },
  },
  caregiversPage: {
    banner: {
      title: 'Finally, a lifeline for your loved one.',
      description: isHc => `Unexpected issues come up all the time when caring for a patient or loved one.  ${isHc ? 'Pocket HC' : 'Pocket RN'} gives you a lifeline expert opinion in just minutes.`,
    },
  },
  nursesPage: {
    banner: {
      title: 'Work on your own time, from your home.',
      description: 'Coach patients and their caregivers through basic skilled nursing procedures.  Learn from and interact with an amazing community of nurses nationwide.',
    },
  },
  contactPage: {
    banner: {
      title: 'Contact Us.',
      description: 'We\'d love to hear your success stories or answer any questions or concerns you may have.',
    },
    share: {
      title: 'Share your story.',
      description: 'Follow us and share your success stories on our social media.',
    },
    follow: {
      title: 'Follow us.',
      description: 'Follow us for updates about our business.',
    },
    contact: {
      title: 'Contact us.',
      description: 'If you have questions or concerns let us help you.',
    },
  },
  NavBar: {
    home: 'Home',
    patients: 'Patients',
    caregivers: 'Caregivers',
    nurses: 'Nurses',
    contact: 'Contact',
    login: 'Login',
  },
};
