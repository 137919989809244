import { useLayoutEffect, useState } from 'react';

export const MAX_MOBILE_WIDTH = 900;

export interface WindowSize {
  width: number;
  height: number;
}

// @SOURCE: https://stackoverflow.com/a/19014495/6090140
export function useWindowSize(): WindowSize {
  const [ size, setSize ] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function useIsMobile(): boolean {
  const [ isMobile, setIsMobile ] = useState(false);
  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth <= MAX_MOBILE_WIDTH);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return isMobile;
}
