import React, { Suspense, lazy, ReactElement, Fragment } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import localizer from './utils/Localizer';
import { LOCALE_NAME_ENGLISH, localeEn } from './locales/en';
import LoadingRoute from './components/routes/LoadingRoute';
import PageRoutes, { PageRoute } from './entities/routes';
import NavBar from './ds/components/NavBar/NavBar';
import useScript from './utils/hooks/useScript';
import { getAnalyticsGlobalSiteTag, injectGoogleAnalytics } from './utils/googleAnalytics';

localizer.setLocale(LOCALE_NAME_ENGLISH, localeEn);
localizer.useFallbackLocale(LOCALE_NAME_ENGLISH);

/* eslint-disable @typescript-eslint/promise-function-async */
const HomePage = lazy(() => import('./components/routes/HomePageRoute'));
const PatientsPage = lazy(() => import('./components/routes/PatientsPageRoute'));
const CaregiversPage = lazy(() => import('./components/routes/CaregiversPageRoute'));
const NursesPage = lazy(() => import('./components/routes/NursesPageRoute'));
const ContactPage = lazy(() => import('./components/routes/ContactPageRoute'));
const PocketRNRedirectPage = lazy(() => import('./components/routes/PocketRNRedirectPageRoute'));
/* eslint-enable @typescript-eslint/promise-function-async */

injectGoogleAnalytics();

const App = (): ReactElement => {
  useScript(`https://www.googletagmanager.com/gtag/js?id=${getAnalyticsGlobalSiteTag()}`);
  const location = useLocation();
  const pageReloading = PageRoutes.redirectOnAppPath(location.pathname);

  if (pageReloading) {
    return <LoadingRoute />;
  }

  return (
    <Fragment>
      <NavBar />
      <Suspense fallback={<LoadingRoute />}>
        <Switch>
          <Route exact path={PageRoute.HomePage} component={HomePage} />
          <Route exact path={PageRoute.PatientsPage} component={PatientsPage} />
          <Route exact path={PageRoute.CaregiversPage} component={CaregiversPage} />
          <Route exact path={PageRoute.NursesPage} component={NursesPage} />
          <Route exact path={PageRoute.ContactPage} component={ContactPage} />
          <Route exact path={PageRoute.PocketRNRedirectPage} component={PocketRNRedirectPage} />
          <Route path="*">
            <Redirect to={PageRoute.HomePage} />
          </Route>
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default App;
