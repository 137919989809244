import React, { ReactElement, Fragment } from 'react';
import styles from './PageLoadingBar.module.css';

export interface PageLoadingBarProps {
  hide?: boolean;
  noNav?: boolean;
}

// @SOURCE: https://codepen.io/dotup/pen/xQqymX
const PageLoadingBar = (props: PageLoadingBarProps): ReactElement => {
  if (props.hide) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Fragment />;
  }
  return (
    <div className={`${styles.wrapper} ${props.noNav ? styles.wrapperNoNav : ''}`}>
      <div className={`${styles.bar} ${styles.bar1}`} />
      <div className={`${styles.bar} ${styles.bar2}`} />
    </div>
  );
};

export default PageLoadingBar;
