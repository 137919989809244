import React, { FunctionComponent, ReactElement } from 'react';
import { useForegroundPage } from '../../../entities/routes';
import styles from './Page.module.css';
import PageLoadingBar from './PageLoadingBar';

export interface PageProps {
  loading?: boolean;
  // if you don't want the page to appear until loaded.
  preloading?: boolean;
  noNav?: boolean;
}

const Page: FunctionComponent<PageProps> = (props): ReactElement => {
  const foreground = useForegroundPage();

  return (
    <section className={`${styles.page} ${foreground ? styles.pageForeground : ''}`}>
      {!props.noNav && <div className={styles.pageNavPlaceholder} />}
      <PageLoadingBar hide={!props.loading && !props.preloading} noNav={props.noNav} />
      {!props.preloading && (
        <section className={`${styles.content} ${props.noNav ? styles.contentNoNav : ''}`}>{props.children}</section>
      )}
    </section>
  );
};

export default Page;
