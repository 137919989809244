import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import styles from './NavBar.module.css';
import Logo from '../Logo/Logo';
import { useIsMobile } from '../../../utils/hooks/windowSize';
import PageRoutes, { PageRoute } from '../../../entities/routes';
import logger from '../../../utils/Logger';
import localizer from '../../../utils/Localizer';
import { useHistory, useLocation } from 'react-router-dom';
import NavBarItem from './NavBarItem';
import HamburgerIcon from '../Icon/Hamburger/Hamburger';
import { isHc } from '../../../utils/environment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum NavBarItemValue {
  Home = 'home',
  Patients = 'patients',
  Caregivers = 'caregivers',
  Nurses = 'nurses',
  Contact = 'contact',
  PocketRN = 'pocketrn',
  Login = 'login',
}

const getRouteDeterminedNavItem = (path: string): NavBarItemValue => {
  const root = PageRoutes.getRootPathFragment(path);
  switch (root) {
    case '':
      return NavBarItemValue.Home;
    case NavBarItemValue.Patients:
      return NavBarItemValue.Patients;
    case NavBarItemValue.Caregivers:
      return NavBarItemValue.Caregivers;
    case NavBarItemValue.Nurses:
      return NavBarItemValue.Nurses;
    case NavBarItemValue.Contact:
      return NavBarItemValue.Contact;
    case NavBarItemValue.PocketRN:
      return NavBarItemValue.PocketRN;
    default:
      logger.logError(new Error(`unexpected root route: ${root}`));
      return NavBarItemValue.Home;
  }
};

const NavBar = (): ReactElement => {
  const locale = localizer.localeMap.NavBar;
  const isMobile = useIsMobile();
  const routeHistory = useHistory();
  const location = useLocation();

  const [ selectedItemValue, setSelectedItemValue ] = useState(
    getRouteDeterminedNavItem(location.pathname),
  );
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    setSelectedItemValue(getRouteDeterminedNavItem(location.pathname));
    setIsOpen(false);
  }, [ location ]);

  const getItemProperties = (): any => {
    return [
      {
        value: NavBarItemValue.Home,
        locale: locale.home,
        onClick: () => selectItem(NavBarItemValue.Home),
      },
      {
        value: NavBarItemValue.Patients,
        locale: locale.patients,
        onClick: () => selectItem(NavBarItemValue.Patients),
      },
      {
        value: NavBarItemValue.Caregivers,
        locale: locale.caregivers,
        onClick: () => selectItem(NavBarItemValue.Caregivers),
      },
      {
        value: NavBarItemValue.Nurses,
        locale: locale.nurses,
        onClick: () => selectItem(NavBarItemValue.Nurses),
      },
      {
        value: NavBarItemValue.Contact,
        locale: locale.contact,
        onClick: () => selectItem(NavBarItemValue.Contact),
      },
    ];
  };

  const selectItem = (newSelectedItemValue: string) => {
    setSelectedItemValue(newSelectedItemValue as NavBarItemValue);
    let route;
    switch (newSelectedItemValue) {
      case NavBarItemValue.Home:
        route = PageRoute.HomePage;
        break;
      case NavBarItemValue.Patients:
        route = PageRoute.PatientsPage;
        break;
      case NavBarItemValue.Caregivers:
        route = PageRoute.CaregiversPage;
        break;
      case NavBarItemValue.Nurses:
        route = PageRoute.NursesPage;
        break;
      case NavBarItemValue.Contact:
        route = PageRoute.ContactPage;
        break;
      default:
        route = PageRoute.HomePage;
        logger.logError(new Error(`unexpected route value: ${newSelectedItemValue}`));
    }
    routeHistory.push(route);
  };

  const goHome = () => {
    selectItem(NavBarItemValue.Home);
  };

  const navigateToLogin = () => {
    if (isHc()) {
      window.location.replace(PageRoute.PocketRNRedirectPage);
    } else {
      // @NOTE: this redirect will not work locally
      window.location.replace(PageRoute.LoginPage);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={styles.wrapper}>
      <Logo onClick={goHome} />
      <div className={styles.itemsWrapper}>
        {!isMobile && (
          <div className={styles.pagesWrapper}>
            {getItemProperties().map((properties: any) => {
              return (
                <NavBarItem
                  key={properties.value}
                  value={properties.value}
                  label={properties.locale}
                  active={properties.value === selectedItemValue}
                  onClick={properties.onClick}
                />
              );
            })}
          </div>
        )}
        <div className={styles.loginWrapper}>
          <NavBarItem
            value={NavBarItemValue.Login}
            label={locale.login}
            active={false}
            onClick={navigateToLogin}
            ignoreMobile
          />
        </div>
        {isMobile && (
          <Fragment>
            <div className={styles.hamburgerWrapper}>
              <HamburgerIcon isOpen={isOpen} toggleMenu={toggleMenu} />
            </div>
            <div className={`${styles.hamburgerMenuWrapper} ${isOpen ? '' : styles.closed}`}>
              <div className={styles.hamburgerItemWrapper}>
                {getItemProperties().map((properties: any) => {
                  return (
                    <NavBarItem
                      key={properties.value}
                      value={properties.value}
                      label={properties.locale}
                      active={properties.value === selectedItemValue}
                      onClick={properties.onClick}
                    />
                  );
                })}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
