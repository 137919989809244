import React, { FunctionComponent, ReactElement } from 'react';
import styles from './Logo.module.css';
import logo from '../../../images/Logo.png';
import logoHC from '../../../images/Logo_HC.png';
import { isHc } from '../../../utils/environment';

interface LogoProps {
  onClick: () => void;
}

const Logo: FunctionComponent<LogoProps> = (props): ReactElement => {
  const src = isHc() ? logoHC : logo;
  const alt = isHc() ? 'Pocket HC Logo' : 'Pocket RN Logo';

  return (
    <div className={styles.wrapper} onClick={props.onClick}>
      <img className={styles.logo} src={src} alt={alt} />
    </div>
  );
};

export default Logo;
