import { isProd } from './environment';

export function injectGoogleAnalytics(): void {
  const win = window as any;
  win.dataLayer = win.dataLayer || [];
  function gtag(...args: any[]) {
    win.dataLayer.push(...args);
  }
  gtag('js', new Date());

  gtag('config', getAnalyticsGlobalSiteTag());
}

export function getAnalyticsGlobalSiteTag(): string {
  return isProd() ? 'G-PKYPB3TYDQ' : 'G-VC02E4LRE0';
}
