import { useEffect } from 'react';

/**
 * @SOURCE: https://stackoverflow.com/a/34425083
 */
const useScript = (url: string): void => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [ url ]);
};

export default useScript;
